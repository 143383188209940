exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-glossary-js": () => import("./../../../src/pages/glossary.js" /* webpackChunkName: "component---src-pages-glossary-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-cancellation-and-refund-js": () => import("./../../../src/pages/legal/cancellation-and-refund.js" /* webpackChunkName: "component---src-pages-legal-cancellation-and-refund-js" */),
  "component---src-pages-legal-disclaimer-js": () => import("./../../../src/pages/legal/disclaimer.js" /* webpackChunkName: "component---src-pages-legal-disclaimer-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../../../src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-legal-terms-of-use-js": () => import("./../../../src/pages/legal/terms-of-use.js" /* webpackChunkName: "component---src-pages-legal-terms-of-use-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-blogpost-js": () => import("./../../../src/templates/blogpost.js" /* webpackChunkName: "component---src-templates-blogpost-js" */),
  "component---src-templates-glossarypost-js": () => import("./../../../src/templates/glossarypost.js" /* webpackChunkName: "component---src-templates-glossarypost-js" */)
}

